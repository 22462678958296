import {dateFormat} from "./dates";

/**
 * Returns CSV-String.
 * @param list
 * @param companyName
 * @returns {*}
 */
const adminPawListToCSV = function (list, companyName) {
    let list2 = []
    for(let elem of list) {
        list2.push({
            Annahme_Datum: dateFormat(elem.claimedDate, "myDate"),
            Annahme_Zeit: dateFormat(elem.claimedDate, "myTime"),
            PAW_Titel: elem.description,
            Partner_Firmenname: elem.companyName,
            Partner_ID: elem.partnerId
        })
    }
    return listToCSV(list2, '_paws_'+companyName)
}
export {adminPawListToCSV}

const adminPartnerListToCSV = function (list) {
    let list2 = []
    for(let elem of list) {
        list2.push({
            FirmenID: elem.company.companyId,
            Firmenname: elem.company.name,
            Vorname: elem.firstName,
            Nachname: elem.lastName,
            Email: elem.email,
            Aktiviert: elem.isActivated? "ja" : "nein",
            Newsletter: elem.newsletterAccepted? "ja" : "nein",
            Strasse: elem.address.street,
            PLZ: elem.address.postCode,
            Stadt: elem.address.city,
            Land: elem.address.country,
            Telefon: "" + elem.company.phoneNumber,
            IK: elem.company.ik,
            PQ_Start: dateFormat(elem.company.pq_since, "myDate"),
            PQ_Ende: dateFormat(elem.company.pq_until, "myDate"),
            Behandlungsbereiche: elem.company.serviceAreas,
            DatenbankID: elem.id,
        })
    }
    return listToCSV(list2, "_partnerliste")
}
export {adminPartnerListToCSV}

const adminNewsletterListToCSV = function (list) {
    let list2 = []
    for(let elem of list) {
        if(!elem.newsletterAccepted) {
            continue;
        }

        list2.push({
            Firmenname: elem.company.name,
            Email: elem.email,
            Vorname: elem.firstName,
            Nachname: elem.lastName,
            PLZ: elem.address.postCode,
            Aktiviert: elem.isActivated? "ja" : "nein",
        })
    }
    if(list2.length < 1){
        return false
    }
    return listToCSV(list2, "_newsletterliste")
}
export {adminNewsletterListToCSV}


function listToCSV(list, filename){
    let json = JSON.parse(JSON.stringify(list))
    let fields = Object.keys(list[0])
    let replacer = function(key, value) { return value === null ? '' : value }
    let csv = json.map(function(row){
        return fields.map(function(fieldName){
            return JSON.stringify(row[fieldName], replacer)
        }).join(';')
    })
    csv.unshift(fields.join(';')) // add header column
    csv = csv.join('\r\n');

    let bb = new Blob([new Uint8Array([0xEF,0xBB,0xBF]), csv], { type: 'text/csv;charset=utf8' });
    let a = document.createElement('a');
    a.download = dateFormat(new Date, "isoDate")+filename+'.csv';
    a.className = "md-hide"
    a.href = window.URL.createObjectURL(bb);
    document.body.appendChild(a)
    a.click();
    return true
}
